






























































import { Component, PropSync, Vue, Prop } from 'vue-property-decorator';

@Component
export default class OpRegisterForm extends Vue {
  @PropSync('opCardNum1', { type: String, required: true })
  syncedOpCardNum1!: string;

  @PropSync('opCardNum2', { type: String, required: true })
  syncedOpCardNum2!: string;

  @PropSync('opCardNum3', { type: String, required: true })
  syncedOpCardNum3!: string;

  @Prop({ type: Boolean })
  isEcopAuthenticated!: boolean;

  @Prop({ type: Boolean })
  required!: boolean;

  @Prop({ type: Boolean })
  hideCaption!: boolean;

  // OP番号の各フィールドを入力したタイミングで、セキュリティコード欄のクリア処理と、入力桁数に応じたフォーカス移動を実施する。
  async onInput(refCurrent: any, refAfter: any) {
    this.$emit('onOpCardNumInput');
    if (!refCurrent) {
      return;
    }
    // input発火直後はvalueの値が未反映のため、1tick待機する
    await this.$nextTick();
    // 入力桁数がフォームの上限桁数の場合かつ次フォームが定義されている場合、次フォームにフォーカスを移動する。
    if (refAfter && refCurrent.value.length >= refCurrent.$attrs.maxlength) {
      refAfter.focus();
      return;
    }
  }
}
